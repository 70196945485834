<template>
  <el-dialog
    title="温度"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="clearTime"
  >
    <div class="tempature_box">
      <template v-if="type === 5">
        <div class="item">
          <span>小于</span>
          <input type="text" maxlength="2" v-model="min" @input="ipt('min')" />
          <span>℃关闭</span>
        </div>

        <div class="item">
          <span>大于</span>
          <input type="text" maxlength="2" v-model="max" @input="ipt('max')" />
          <span>℃开启</span>
        </div>
      </template>
      <template v-else>
        <div class="item">
          <span>小于</span>
          <input type="text" maxlength="2" v-model="min" @input="ipt('min')" />
          <span>℃开启</span>
        </div>

        <div class="item">
          <span>大于</span>
          <input type="text" maxlength="2" v-model="max" @input="ipt('max')" />
          <span>℃关闭</span>
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddPreConfig, UpdatePreConfig } from "@/api";

export default {
  data() {
    return {
      // 是否显示温度弹窗
      dialogVisible: false,
      // 是否为编辑
      isEdit: false,
      min: "",
      max: "",
      id_project: "",
      // 修改项
      id: "",
      // 5 最大值在左边, 最小值在右边 6 7 刚好相反
      type: "",
    };
  },
  methods: {
    // 清除时间
    clearTime() {
      this.min = this.max = "";
    },

    // 限制只能输入数字
    ipt(key) {
      this[key] = this[key].replace(/[^\d]/g, "");
    },

    // 添加
    add(orgId, type) {
      this.type = type;
      this.id_project = orgId;
      this.isEdit = false;
      this.dialogVisible = true;
    },

    // 编辑
    edit(item, type) {
      this.type = type;
      this.id = item.id;
      this.max = item.max + "";
      this.min = item.min + "";
      this.isEdit = true;
      this.dialogVisible = true;
    },

    // 点击确定
    confirm() {
      if (this.min === "" || this.max === "") {
        this.$message.warning("请检查温度输入");
        return;
      }
      const min = this.min / 1;
      const max = this.max / 1;
      if (min > max) {
        return this.$message.warning("输入数据不合理");
      }
      if (this.isEdit) {
        this.updateTempature();
      } else {
        if (this.type === 5) {
          this.addTempature("water_replenishment_temp_array");
        } else if (this.type === 6) {
          this.addTempature("water_back_temp_array");
        } else {
          this.addTempature("water_production_temp_array");
        }
      }
    },

    // 添加温度 water_replenishment_temp_array
    async addTempature(key) {
      const params = {
        id_project: this.id_project,
      };
      const min = this.min / 1;
      const max = this.max / 1;
      const temp = {
        min,
        max,
      };
      params[key] = [temp];
      const { ret, data, msg } = await AddPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "添加温度失败!");
      }
      this.$message.success("添加成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },

    // 更新温度
    async updateTempature() {
      const max = this.max / 1;
      const min = this.min / 1;
      const params = {
        param_type: 1,
        id: this.id,
        max: max > min ? max : min,
        min: max > min ? min : max,
      };
      const { ret, data, msg } = await UpdatePreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "更新温度失败!");
      }
      this.$message.success("更新成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tempature_box {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px;

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 0 6px;
    }

    input {
      width: 64px;
      height: 32px;
      border: 1px solid #999999;
      border-radius: 4px;
      outline: none;
      text-align: center;
    }
  }
}
</style>
