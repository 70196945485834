import { render, staticRenderFns } from "./water_level.vue?vue&type=template&id=0b4a7080&scoped=true&"
import script from "./water_level.vue?vue&type=script&lang=js&"
export * from "./water_level.vue?vue&type=script&lang=js&"
import style0 from "./water_level.vue?vue&type=style&index=0&id=0b4a7080&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4a7080",
  null
  
)

export default component.exports