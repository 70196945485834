<template>
  <el-dialog
    :title="(isEdit ? '修改' : '添加') + typeName + '时间'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="clearTime"
  >
    <div class="time_box">
      <el-time-picker
        is-range
        v-model="form.date"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
        value="HH:mm:ss"
        value-format="HH:mm:ss"
      >
      </el-time-picker>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddPreConfig, UpdatePreConfig } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹出框
      dialogVisible: false,
      form: {
        // 绑定日期
        date: ['', '']
      },
      // 类型
      type: '',
      // 类名名称
      typeName: '',
      // 项目
      id_project: '',
      // 添加还是修改
      isEdit: false,
      // 更新id
      id: '',
      // 更新类型
      param_type: ''
    }
  },
  methods: {
    // 弹窗关闭事件
    clearTime() {
      this.date = null
    },

    // 添加
    add(project, type) {
      this.id_project = project
      this.isEdit = false
      this.type = type
      this.typeName = ['供水', '补水', '产水'][type - 1]
      this.dialogVisible = true
    },

    // 更新
    edit(item, type) {
      this.isEdit = true
      this.id = item.id
      this.type = type
      this.typeName = ['供水', '补水', '产水'][type - 1]
      this.param_type = item.param_type
      this.form.date = [item.time_start, item.time_end]
      this.form.remark = item.remark
      this.dialogVisible = true
    },

    // 确定添加供水时间
    confirm() {
      if (!this.form.date || !this.form.date[0] || !this.form.date[1]) {
        this.$message.warning('请选择时间')
        return
      }
      if (this.form.date[0] === this.form.date[1]) {
        this.$message.warning('开始时间和结束时间不能相同')
        return
      }
      if (this.isEdit) {
        this.updateConfig()
      } else {
        if (this.type === 1) {
          // 添加供水时间
          this.addTime('water_supply_time_array')
        } else if (this.type === 2) {
          this.addTime('water_replenishment_time_array')
        } else if (this.type === 3) {
          this.addTime('water_production_time_array')
        }
      }
    },

    // 添加供水时间
    async addTime(key) {
      const params = {
        id_project: this.id_project
      }
      params[key] = [
        {
          time_start: this.form.date[0],
          time_end: this.form.date[1]
        }
      ]
      const { ret, data, msg } = await AddPreConfig(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加时间失败!')
      }
      this.$message.success('添加时间成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 更新 供水时间 产水时间 补水时间
    async updateConfig() {
      const params = {
        id: this.id,
        param_type: this.param_type,
        time_start: this.form.date[0],
        time_end: this.form.date[1]
      }
      const { ret, data, msg } = await UpdatePreConfig(params)
      if (ret !== 0) {
        return this.$message.error(msg || '更新时间失败!')
      }
      this.$message.success('更新成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='scss' scoped>
.time_box {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
