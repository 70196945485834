<template>
  <div class="main">
    <div class="pre_config">
      <div class="select_box">
        <div class="select_item" v-supAdmin>
          <div class="label">项目：</div>
          <el-cascader
            v-model="cascaderValue"
            :options="cascaderOptions"
            @change="cascaderSelected"
          ></el-cascader>
        </div>

        <div class="select_item" v-operator>
          <div class="label">项目：</div>
          <el-select
            v-model="id_project"
            placeholder="请选择"
            style="width: 100%"
            @change="getPreConfig"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name_project"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <template v-if="id_project">
        <div class="project_title">{{ getProjectName }}</div>

        <div class="menu_content">
          <div class="menu_box">
            <!-- 七个分类 -->
            <ul class="type_box">
              <li
                v-for="(item, index) in leftContent"
                :key="index"
                :class="item.type === type ? 'current' : ''"
                @click="changeType(item.type)"
              >
                <div class="left_box">
                  <img :src="item.img" alt="" />
                  <span>{{ item.font }}</span>
                </div>
                <img
                  :class="type === item.type ? 'right right_rotate' : 'right'"
                  src="../../assets/pre_config_right.png"
                />
              </li>
            </ul>
          </div>
          <div class="content_box">
            <div class="content_title1">
              <span>{{ type | typeFmt }}</span>
              <div
                class="right"
                @click="addTime"
                v-if="type === 1 || type === 2 || type === 3"
              >
                <i class="el-icon-plus"></i>
                <span>添加{{ type | typeFmt }}</span>
              </div>

              <div class="right" @click="addWaterLevel" v-else-if="type === 4">
                <i class="el-icon-plus"></i>
                <span>添加{{ type | typeFmt }}</span>
              </div>

              <div class="right" @click="addTempature" v-else>
                <i class="el-icon-plus"></i>
                <span>添加{{ type | typeFmt }}</span>
              </div>
            </div>
            <div class="mini_title">
              <div class="title">
                <span>{{ type | typeFmt }}</span>
                <span>操作</span>
              </div>
            </div>
            <div class="item_box">
              <el-scrollbar style="height: 100%">
                <ul>
                  <template v-if="type === 1 || type === 2 || type === 3">
                    <!-- 模板1 -->
                    <li class="time_item" v-for="item in getList" :key="item.id">
                      <div class="time">{{ item.time_start }} - {{ item.time_end }}</div>
                      <div class="right">
                        <img src="../../assets/edit_8.png" @click="changeTime(item)" />
                        <img src="../../assets/del_8.png" @click="delTime(item)" />
                      </div>
                    </li>
                  </template>

                  <template v-else-if="type === 4">
                    <!-- 模板2 -->
                    <li class="water_item" v-for="item in getList" :key="item.id">
                      <div class="left">
                        <div class="item">
                          <span>小于{{ item.min }}％开启</span>
                        </div>
                        <div class="item">
                          <span>大于{{ item.max }}％关闭</span>
                        </div>
                      </div>
                      <div class="right">
                        <img
                          @click="editWaterLevel(item)"
                          src="../../assets/edit_8.png"
                        />
                        <img @click="delWaterLevel(item)" src="../../assets/del_8.png" />
                      </div>
                    </li>
                  </template>

                  <template v-else>
                    <!-- 模板3 -->
                    <li class="water_item" v-for="item in getList" :key="item.id">
                      <div class="left">
                        <div class="item">
                          <span
                            >小于{{ item.min }}℃{{ type === 5 ? "关闭" : "开启" }}</span
                          >
                        </div>
                        <div class="item">
                          <span
                            >大于{{ item.max }}℃{{ type === 5 ? "开启" : "关闭" }}
                          </span>
                        </div>
                      </div>
                      <div class="right">
                        <img @click="editTempature(item)" src="../../assets/edit_8.png" />
                        <img @click="delTempature(item)" src="../../assets/del_8.png" />
                      </div>
                    </li>
                  </template>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </template>

      <el-empty v-else description="请选择项目后查询" :image-size="400"></el-empty>
    </div>

    <TimePicker ref="timePickerRef" @refresh="getPreConfig" />
    <WaterLevel ref="waterLevelRef" @refresh="getPreConfig" />
    <TempaturePicker ref="tempatureRef" @refresh="getPreConfig" />
  </div>
</template>

<script>
import { GetOperatorList, GetProjectList, GetPreConfig, DelPreConfig } from "@/api";
import Img1 from "../../assets/pre_config_1.png";
import Img2 from "../../assets/pre_config_2.png";
import Img3 from "../../assets/pre_config_3.png";
import Img4 from "../../assets/pre_config_4.png";
import Img5 from "../../assets/pre_config_5.png";
import Img6 from "../../assets/pre_config_6.png";
import Img7 from "../../assets/pre_config_7.png";
import TimePicker from "./dialog/time_picker.vue";
import WaterLevel from "./dialog/water_level.vue";
import TempaturePicker from "./dialog/tempature_picker.vue";

const leftContent = [
  {
    type: 1,
    img: Img1,
    font: "供水时间",
  },
  {
    type: 2,
    img: Img2,
    font: "补水时间",
  },
  {
    type: 3,
    img: Img3,
    font: "产水时间",
  },
  {
    type: 4,
    img: Img4,
    font: "目标水位",
  },
  {
    type: 5,
    img: Img5,
    font: "补水目标温度",
  },
  {
    type: 6,
    img: Img6,
    font: "回水目标温度",
  },
  {
    type: 7,
    img: Img7,
    font: "产水目标温度",
  },
];

export default {
  name: "pre_config",
  components: { TimePicker, WaterLevel, TempaturePicker },
  data() {
    return {
      // 角色
      role: "",
      leftContent,
      // 级联选择器选中数据
      cascaderValue: [],
      // 级联选择器数据
      cascaderOptions: [],
      // 项目
      id_project: "",
      // 项目列表
      projectList: [],
      // 当前选中的类型
      type: 1,
      // 配置对象
      preConfigList: {},
      // 当前所在的步骤 1, 运营商项目页面 2, 设置页面
      step: 1,
    };
  },

  methods: {
    // role === 100 超管登录 会走这条路线 运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList();
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.operatorList = data.data;
      const option = [];
      data.data.map((i) => {
        const temp = {
          label: i.name_operator,
          value: i.id_operator,
        };
        const children = [];
        i.project_list.map((j) => {
          children.push({
            label: j.name_org,
            value: j.id_project,
          });
        });
        temp.children = children;
        option.push(temp);
      });

      this.cascaderOptions = option;
      this.$nextTick(() => {
        // 动态设置一个默认选中
        for (let i = 0; i < option.length; i++) {
          if (option[i].children && option[i].children.length) {
            this.cascaderValue = [option[i].value, option[i].children[0].value];
            this.id_project = option[i].children[0].value;
            this.getPreConfig();
            break;
          }
        }
      });
    },

    // role !== 100 获取项目列表
    async getProjectList() {
      const { ret, data, msg } = await GetProjectList();
      if (ret !== 0) {
        return this.$message.error(msg || "获取项目列表失败!");
      }
      this.projectList = data.data;
      if (data.data.length) {
        this.id_project = data.data[0].id;
        this.getPreConfig();
      }
    },

    // 级联选择器选中项目
    cascaderSelected(p) {
      this.id_project = p[1];
      this.getPreConfig();
    },

    // 修改类型
    changeType(type) {
      this.type = type;
    },

    // 选择了项目
    selectedProject(ev) {
      this.getPreConfig();
    },

    // 获取当前项目的配置信息
    async getPreConfig() {
      const params = {
        id_project: this.id_project,
      };
      const { ret, data, msg } = await GetPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "获取预设参数失败!");
      }
      this.preConfigList = data;
    },

    // 添加 供水 补水 产水 时间
    addTime() {
      this.$refs.timePickerRef.add(this.id_project, this.type);
    },

    // 更新供水 产水 补水 时间
    changeTime(item) {
      this.$refs.timePickerRef.edit(Object.assign(item, { param_type: 0 }), this.type);
    },

    // 删除 供水时间 产水时间 补水时间
    async delTime(item) {
      const { ret, data, msg } = await DelPreConfig({
        id: item.id,
        param_type: 0,
      });
      if (ret !== 0) {
        return this.$message.error(msg || "删除失败!");
      }
      this.$message.success("删除成功");
      this.getPreConfig();
    },

    // 添加水位
    addWaterLevel() {
      this.$refs.waterLevelRef.add(this.id_project);
    },

    // 修改水位
    editWaterLevel(item) {
      this.$refs.waterLevelRef.edit(item, this.type);
    },

    // 删除水位
    async delWaterLevel(item) {
      const { ret, data, msg } = await DelPreConfig({
        id: item.id,
        param_type: 1,
      });
      if (ret !== 0) {
        return this.$message.error(msg || "删除失败!");
      }
      this.$message.success("删除成功");
      this.getPreConfig();
    },

    // 添加 补水 回水 产水 目标温度
    addTempature() {
      this.$refs.tempatureRef.add(this.id_project, this.type);
    },

    // 编辑温度
    editTempature(item) {
      this.$refs.tempatureRef.edit(item, this.type);
    },

    // 删除温度
    async delTempature(item) {
      const params = {
        id: item.id,
        param_type: 1,
      };
      const { ret, data, msg } = await DelPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "删除失败!");
      }
      this.$message.success("删除成功");
      this.getPreConfig();
    },
  },

  created() {
    this.role = this.$store.getters.role;
    if (this.role === 100) {
      this.getOperatorList();
    } else {
      this.getProjectList();
    }
  },

  filters: {
    typeFmt(v) {
      const arr = [
        "供水时间",
        "补水时间",
        "产水时间",
        "目标水位",
        "补水目标温度",
        "回水目标温度",
        "产水目标温度",
      ];
      return arr[v - 1];
    },
  },

  computed: {
    // 计算应该显示的运营商列表
    computedProject() {
      if (!this.id_operator) {
        return this.projectList;
      } else {
        return this.projectList.filter((i) => {
          return i.id_operator === this.id_operator;
        });
      }
    },
    // 计算当前选中的项目名称
    getProjectName() {
      if (!this.id_project) {
        return "请选择项目";
      } else {
        if (this.role === 100) {
          const option = this.cascaderOptions;
          const value = this.cascaderValue;
          const index1 = option.findIndex((i) => i.value === value[0]);
          const index2 = option[index1].children.findIndex((i) => i.value === value[1]);
          return option[index1].children[index2].label;
        } else {
          const curIndex = this.projectList.findIndex((i) => i.id === this.id_project);
          return this.projectList[curIndex].name_project;
        }
      }
    },
    // 获取渲染的数据
    getList() {
      switch (this.type) {
        case 1:
          return this.preConfigList.water_supply_time_array || [];
        case 2:
          return this.preConfigList.water_replenishment_time_array || [];
        case 3:
          return this.preConfigList.water_production_time_array || [];
        case 4:
          return this.preConfigList.level_target_array || [];
        case 5:
          return this.preConfigList.water_replenishment_temp_array || [];
        case 6:
          return this.preConfigList.water_back_temp_array || [];
        case 7:
          return this.preConfigList.water_production_temp_array || [];
        default:
          return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pre_config {
  width: 100%;
  height: 100%;
  border-radius: $radius;

  .select_box {
    height: 52px;
    display: flex;
    align-items: center;
    padding-bottom: 12px;

    .select_item {
      display: flex;
      align-items: center;

      .label {
        width: 80px;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .btn_item {
      margin-left: 12px;
    }
  }

  .project_title {
    height: 64px;
    line-height: 64px;
    padding-left: 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1b2351;
    background-color: #ffffff;
  }

  .menu_content {
    display: flex;

    .menu_box {
      width: 314px;
      background: #fff;

      .select_box {
        padding: 20px 30px;

        .select {
          width: 100%;
        }
      }
      .type_box {
        li {
          cursor: pointer;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 21px 0 24px;

          .left_box {
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              transform: translateY(4px);
            }

            span {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-left: 7px;
            }
          }

          .right {
            width: 21px;
            height: 21px;
            transition: all 0.3s;
          }

          .right_rotate {
            transform: rotate(-90deg);
          }
        }

        .current {
          background-color: #f7f9fb;
          cursor: default;
        }
      }
    }

    .content_box {
      flex: 1;
      background: #f7f9fb;

      .content_title1 {
        height: 60px;
        padding: 0 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1b2351;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right {
          cursor: pointer;

          i {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2a47b0;
          }
          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2a47b0;
            margin-left: 4px;
          }
        }
      }

      .mini_title {
        padding: 0 17px;

        .title {
          height: 50px;
          background: #fafafb;
          border: 1px solid #eff2f7;
          border-radius: 4px 4px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 0 36px;

          span {
            flex: 3;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #5b667f;

            &:nth-child(2) {
              flex: 2;
              text-align: right;
              padding-right: 68px;
            }
          }
        }
      }

      .item_box {
        height: calc(100vh - 350px);
        padding: 0 17px;

        .time_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          padding-left: 36px;
          border-bottom: 1px solid #eff2f7;
          border-right: 1px solid #eff2f7;
          border-left: 1px solid #eff2f7;

          &:nth-child(2n + 1) {
            background: #ffffff;
          }

          &:nth-child(2n) {
            background: #fcfdfe;
          }

          .time {
            flex: 3;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .right {
            flex: 2;
            text-align: right;
            padding-right: 34px;

            img {
              width: 22px;
              height: 22px;
              margin: 0 14px;
              cursor: pointer;
            }
          }
        }

        .water_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          padding-left: 36px;
          border-bottom: 1px solid #eff2f7;
          border-right: 1px solid #eff2f7;
          border-left: 1px solid #eff2f7;

          &:nth-child(2n + 1) {
            background: #ffffff;
          }

          &:nth-child(2n) {
            background: #fcfdfe;
          }

          .left {
            flex: 3;
            display: flex;
            align-items: center;

            .item {
              display: flex;
              align-items: center;
              margin-right: 36px;

              span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }

              input {
                width: 60px;
                height: 32px;
                border: 1px solid #999999;
                border-radius: 4px;
                margin: 0 4px;
                outline: none;
              }
            }
          }

          .right {
            flex: 2;
            text-align: right;
            padding-right: 34px;

            img {
              width: 22px;
              height: 22px;
              margin: 0 14px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
